import React, { useCallback, useEffect, useRef, useState } from "react";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
//@ts-ignore
import { DataTableDataType } from "../../../../types";
import { useDataTable } from "../DataTableContext";
import DataTableInfiniteLoader from "../common/DataTableInfiniteLoader";
import Row from "../common/Row";
import { RowRendererProps } from "../types/DataTableComponentTypes";

const DataTableVirtualized = <T extends DataTableDataType>() => {
  const {
    data,
    widths: { tableWidth },
    heights: { loadingHeight, rowHeight },
    columnSizes,
    loading,
    rowPrefix,
    pagination: { setOnPaginationChanged },
    scroll: { scrollToIndex, onTableScroll },
    infiniteLoader,
  } = useDataTable<T>();

  const list = useRef<List>();

  const [cellMeasurerCache] = useState(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: rowHeight,
    }),
  );

  const paginationChanged = useCallback(() => {
    if (!!list?.current?.scrollToRow) {
      list.current!.scrollToRow(0);
    }
  }, [list]);

  const recompute = useCallback(
    (index?: number) => {
      setTimeout(() => {
        if (typeof index != "undefined" && index > -1) {
          cellMeasurerCache.clear(index, 0);
        }
        if (!!list?.current?.recomputeRowHeights) {
          list!.current!.recomputeRowHeights(index);
        }
      }, 10);
    },
    [list, cellMeasurerCache],
  );

  useEffect(() => {
    setOnPaginationChanged(paginationChanged);
  }, [paginationChanged]);

  useEffect(() => {
    recompute();
  }, [data]);

  const RowRenderer: React.FC<React.PropsWithChildren<RowRendererProps>> = ({ index, key, style, parent }) => {
    const value: T = data[index];
    return (
      <CellMeasurer
        key={rowPrefix + index + key + JSON.stringify(columnSizes)}
        cache={cellMeasurerCache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <Row
          key={index}
          style={style}
          index={index}
          value={value}
          recompute={recompute}
          loading={loading || index >= data.length}
        />
      </CellMeasurer>
    );
  };

  return (
    <AutoSizer>
      {({ height, width }) => {
        const finalTableWidth = Math.max(width, tableWidth);
        const finalHeight = loading ? loadingHeight : height;
        const loadingRows = Math.floor(finalHeight / rowHeight);
        return infiniteLoader ? (
          <DataTableInfiniteLoader data={data} {...infiniteLoader}>
            {({ onRowsRendered, registerChild }) => (
              <List
                ref={(_list) => {
                  list.current = _list || undefined;
                  registerChild(_list);
                }}
                width={finalTableWidth}
                height={finalHeight}
                rowCount={infiniteLoader.rowCount}
                rowHeight={cellMeasurerCache.rowHeight}
                rowRenderer={RowRenderer}
                scrollToIndex={scrollToIndex}
                onScroll={onTableScroll}
                onRowsRendered={onRowsRendered}
              />
            )}
          </DataTableInfiniteLoader>
        ) : (
          <List
            ref={(_list) => {
              list.current = _list || undefined;
            }}
            width={finalTableWidth}
            height={finalHeight}
            rowCount={loading ? loadingRows : data.length}
            rowHeight={cellMeasurerCache.rowHeight}
            rowRenderer={RowRenderer}
            scrollToIndex={scrollToIndex}
            onScroll={onTableScroll}
          />
        );
      }}
    </AutoSizer>
  );
};

export default DataTableVirtualized;
