import React, { useMemo } from "react";
import { DebounceInput } from "../../../../form";
import {
  DataTableAllInputProps,
  DataTableColumn,
  DataTableDataType,
  DataTableInputProps,
  FormatterType,
} from "../../../../types";
import { isFunction } from "../../../../utils";
import PulseFiller from "../../../pulse/PulseFiller";
import { LoadingHolder } from "../DatatableStyles";
import TextFormatter from "../formatters/TextFormatter";
import { formatterPropsCompare } from "../utils/TableUtils";

interface DataItemProps<T extends DataTableDataType> {
  loading?: boolean;
  col: DataTableColumn<T>;
  columnIndex: number;
  value: T;
  expand: () => void;
  expanded?: boolean;
  index: number;
  isSubrow?: boolean;
}

const DataItem = <T extends DataTableDataType>(props: DataItemProps<T>) => {
  const { col, loading = false, columnIndex, value, expand, expanded, index, isSubrow = false } = props;
  const { type = "view", key } = col;
  const idPrefix = `body-cell-${columnIndex}-${String(key)}`;

  if (loading) {
    return (
      <LoadingHolder>
        <PulseFiller />
      </LoadingHolder>
    );
  } else if (type === "input") {
    const { inputProps = {} } = col;
    const finalInputProps: DataTableInputProps = isFunction(inputProps)
      ? (inputProps as (row: T) => DataTableAllInputProps)(value)
      : (inputProps as DataTableAllInputProps);

    return (
      <DebounceInput
        {...finalInputProps}
        timeout={1000}
        id={`${idPrefix}-input`}
        value={value[key as keyof T] as string}
        onChange={(inputValue: string) => {
          if (col.onChange) {
            col.onChange(index, key as keyof T, inputValue as any);
          }
        }}
      />
    );
  } else if (type === "view") {
    const { editFormatter, readonlyFormatter, cellClass, icon, onClick } = col;
    const editable = !!editFormatter;
    const keyStr = `${idPrefix}-formatter`;
    const formatterProps = {
      className: cellClass,
      columnKey: key,
      row: value,
      value: value[key as keyof T],
      rowIndex: index,
      subrow: isSubrow,
      expand: expand,
      expanded: expanded,
      icon: icon,
      onClick: onClick,
      editable: editable,
    };

    const Formatter = useMemo(() => {
      if (editable) {
        const Formatter: React.FC<React.PropsWithChildren<FormatterType<T>>> = editFormatter;
        return Formatter;
      } else {
        const Formatter: React.FC<React.PropsWithChildren<FormatterType<T>>> = !!readonlyFormatter
          ? readonlyFormatter
          : TextFormatter;
        const MemoFormatter = React.memo(Formatter, formatterPropsCompare);
        return MemoFormatter;
      }
    }, [editable, editFormatter, readonlyFormatter]);

    return <Formatter key={keyStr} {...formatterProps} />;
  } else {
    return null;
  }
};

export default DataItem;
