import React, { useState } from "react";
import { useDebounce as useDebounceCallback } from "../../../utils";
import Input, { InputProps } from "../input/Input";

type DebounceInputProps = InputProps & {
  timeout?: number;
};

const DebounceInput: React.FC<React.PropsWithChildren<DebounceInputProps>> = ({
  value,
  onChange,
  timeout,
  ...rest
}) => {
  const [localValue, setLocalValue] = useState<string>(value);

  const onChangeLocal = (value: string) => {
    setLocalValue(value);
    updateDebounce(value);
  };

  const updateDebounce = useDebounceCallback((value: string) => {
    onChange(value);
  }, timeout);

  return <Input {...rest} value={localValue} onChange={onChangeLocal} />;
};

export default DebounceInput;
