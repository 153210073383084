import {
  FloatingArrow,
  FloatingPortal,
  arrow,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import clsx from "clsx";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDevices } from "../../../hooks";
import { PopoverPlacement, PopoverStrategy, TriggerTypes, Triggers } from "../../../types/PopoverTypes";
import { uniqueStr } from "../../../utils";

export interface GenericPopoverProps {
  children: React.ReactNode;
  trigger?: TriggerTypes;
  placement?: PopoverPlacement;
  strategy?: PopoverStrategy;
  delay?: number;
  disabled?: boolean;
  singleOpen?: boolean;
  arrowColor?: string;
  showArrow?: boolean;
  reposition?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
}

export type PopoverRef = HTMLElement;

export interface GenericPopoverFinalProps extends GenericPopoverProps {
  element: React.RefObject<PopoverRef>;
}

//this adds the react properties to the HTML element which does not use camelCase properties
const addProperties = (element: HTMLElement, props: Record<string, any>) => {
  for (const key in props) {
    const lowerKey = key.toLowerCase();
    //@ts-ignore
    element[lowerKey] = props[key];
  }
};

const GenericPopover = ({
  children,
  delay = 0,
  trigger = "hover",
  placement = "bottom",
  disabled = false,
  singleOpen = true,
  strategy = "absolute",
  arrowColor = "",
  showArrow = false,
  element,
  setIsOpen: setIsOpenProp,
}: GenericPopoverFinalProps) => {
  const { isMobile } = useDevices();
  const popoverId = useMemo(() => `bcr-popover-${uniqueStr()}`, []);
  const [finalTrigger, setFinalTrigger] = useState<TriggerTypes>(trigger);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const arrowRef = useRef(null);

  const onOpenChange = (open: boolean) => {
    if (!!setIsOpenProp) {
      setIsOpenProp(open);
    }
    setIsOpen(open);
  };

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange,
    placement,
    strategy,
    middleware: [
      arrow({
        element: arrowRef,
      }),
      offset(7),
    ],
    nodeId: popoverId,
  });

  const hover = useHover(context, {
    enabled: finalTrigger === Triggers.HOVER && !disabled,
    restMs: delay,
  });
  const click = useClick(context, {
    enabled: finalTrigger === Triggers.CLICK && !disabled,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, hover, dismiss, role]);

  useEffect(() => {
    if (isMobile) {
      setFinalTrigger("click");
    } else {
      setFinalTrigger(trigger);
    }
  }, [trigger, isMobile]);

  useEffect(() => {
    if (!!element.current) {
      refs.setReference(element.current);
      addProperties(element.current, getReferenceProps());
    }
  }, [element]);

  return isOpen ? (
    <FloatingPortal>
      <div
        ref={refs.setFloating}
        style={floatingStyles}
        className={clsx(popoverId, "bcr-popover")}
        {...getFloatingProps()}
      >
        {!!showArrow && <FloatingArrow context={context} ref={arrowRef} stroke={arrowColor} fill={arrowColor} />}
        {children}
      </div>
    </FloatingPortal>
  ) : null;
};

export default GenericPopover;
