import React from "react";
import { DataTableDataType } from "../../../../types";
import { useDataTable } from "../DataTableContext";
import Row from "../common/Row";

const DataTableNonVirtualized = <T extends DataTableDataType>() => {
  const { data, loading } = useDataTable();

  return (
    <>
      {data.map((value, index: number) => {
        return (
          <Row
            key={JSON.stringify(value)}
            index={index}
            value={value}
            loading={loading || index >= data.length}
            style={{}}
          />
        );
      })}
    </>
  );
};

export default DataTableNonVirtualized;
